import React, { Component } from 'react';
import GatsbyLink from '../../../GatsbyLink';
import './RelatedPass.scss';
import Carousel from '../../../Carousel';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import PostListItem from '../../PostListItem'
import { PostListNavigation } from '../../PostListNavigation'

class ThisSlider extends Component {
  render () {
    const { posts, siteMetadata, pathPrefix, showAuthor } = this.props;
    const settings = {
      container: `related-carousel`,
      nav: false,
      mouseDrag: true,
      controls: true,
      loop: false,
      gutter: 15,
      slideBy: 1,
      controlsContainer: '.related-nav',
      responsive: {
        1600: {
          items: 3,
        },
        1024: {
          items: 3,
        },
        640: {
          items: 3,
        },
        420: {
          items: 1,
        }
      }
    };

    return (
      <div className="slider">
        <div className="related-nav">
          <button type="button" title="Prev"><FaChevronLeft/></button>
          <button type="button" title="Next"><FaChevronRight/></button>
        </div>
        <Carousel settings={settings}>
          {posts && posts.map((post, slide_count) => post.node.slug !== 'gatsby' &&
            <PostListItem key={slide_count} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} />
          )
        }
        </Carousel>
      </div>
    )
  }
}

class ListView extends Component {
  render () {
    const { posts, siteMetadata, pathPrefix, showAuthor } = this.props;
    return (
      <div className="slider list-view">
          {posts && posts.map((post, slide_count) => post.node.slug !== 'gatsby' &&
            <PostListItem
            key={slide_count}
            data={post.node}
            siteMetadata={siteMetadata}
            pathPrefix={pathPrefix}
            showAuthor={showAuthor}
            />
          )
        }
      </div>
    )
  }
}

export const RelatedPass = (props) => {
  const { data, title = 'Related Posts', listView, contentType = 'post', parentCategory } = props;
  const { allWordpressPost, allWordpressWpCaseStudies, wordpressWpSettings, allWordpressCategory, siteSettings } = data;
  const postPass = allWordpressWpCaseStudies ? allWordpressWpCaseStudies : allWordpressPost
  const { showAuthor } = siteSettings.options
  const { edges: posts } = postPass
  /* We are going to filter posts via case-study */
  let parentCategoryArray = [];
  let selectedPosts = [];
  if (parentCategory) {
    parentCategoryArray = parentCategory.map(item => item.wordpress_id);
    selectedPosts = posts.filter(item => parentCategoryArray.includes(item.node.wordpress_id));
  }
  const postFeedLink = 
    contentType === 'case-study' 
    ? { url: `/case-studies/`, title: 'View all'}
    : { url: `/${wordpressWpSettings.blogSlug}/` , title: 'View all' }
  const pathPrefix = contentType === 'case-study' ? 'case-studies' : wordpressWpSettings.blogSlug
  return (
    <section className={`post-feed ${contentType}-list`}>
      {listView && <PostListNavigation pathPrefix={wordpressWpSettings.blogSlug} />}
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            {!listView && title && <h3 className="title" dangerouslySetInnerHTML={{ __html: title }} />}
            {!listView && <ThisSlider posts={selectedPosts ? selectedPosts : posts} siteMetadata={wordpressWpSettings} pathPrefix={pathPrefix} showAuthor={showAuthor} />}
            {listView && <ListView posts={selectedPosts ? selectedPosts : posts} siteMetadata={wordpressWpSettings} pathPrefix={pathPrefix} showAuthor={showAuthor} />}
            {postFeedLink && <GatsbyLink to={postFeedLink.url} label={postFeedLink.title} className="link">{postFeedLink.title}</GatsbyLink> }
          </div>
        </div>
      </div>
    </section>

  )
}
