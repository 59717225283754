import React, { useRef, useEffect, useState } from 'react'
import { slugify } from '../../../utils/helpers'

export const Phone = props => {
  const { field, value, handleChange } = props
  const { name, placeholder, required, className } = field

  const [isFocused, setIsFocused] = useState(false)

  const phoneInputRef = useRef(null)

  const [hasValue, setHasValue] = useState(false)
  useEffect(() => {
    if (phoneInputRef.current) {
      if (phoneInputRef.current.value !== '') {
        setHasValue(true)
      } else {
        setHasValue(false)
      }
    }
  }, [phoneInputRef?.current?.value])
  return (
    <div
      className={`${className}${value[slugify(name)] ? ' active' : ''}${
        required ? ' required' : ''
      }`}
    >
      <label
        htmlFor={name}
        className={isFocused || hasValue ? 'label--active' : ''}
      >
        {placeholder}
        {required && `*`}
      </label>
      <input
        ref={phoneInputRef}
        name={slugify(name)}
        id={slugify(name)}
        type="phone"
        placeholder={placeholder}
        required={!!required}
        onChange={handleChange}
        value={value[slugify(name)] ? value[slugify(name)] : ''}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
      />
    </div>
  )
}
