import React, { useEffect, useRef, useState } from 'react'
import { slugify } from '../../../utils/helpers'

export const Email = props => {
  const { field, value, handleChange } = props
  const { type, name, placeholder, required, className } = field
  const slug = slugify(name)

  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef(null)

  const [hasValue, setHasValue] = useState(false)
  useEffect(() => {
    if (inputRef.current) {
      if (inputRef.current.value !== '') {
        setHasValue(true)
      } else {
        setHasValue(false)
      }
    }
  }, [inputRef?.current?.value])
  return (
    <div
      className={`${className}${
        value[slugify(name)] || isFocused ? ' active' : ''
      }${required ? ' required' : ''}`}
    >
      <label
        htmlFor={name}
        className={isFocused || hasValue ? 'label--active' : ''}
      >
        {placeholder}
        {required && `*`}
      </label>
      <input
        ref={inputRef}
        name={slug}
        id={slug}
        type="text"
        placeholder={placeholder}
        required={!!required}
        onChange={handleChange}
        value={value[slugify(name)] ? value[slugify(name)] : ''}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
      />
    </div>
  )
}
