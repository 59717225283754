import React, { useEffect, useRef, useState } from 'react'
import { slugify } from '../../../utils/helpers'

export const Textarea = props => {
  const { field, value, handleChange } = props
  const { name, placeholder, required, className } = field

  const [isFocused, setIsFocused] = useState(false)
  const textAreaRef = useRef(null)

  const [hasValue, setHasValue] = useState(false)
  useEffect(() => {
    if (textAreaRef.current) {
      if (textAreaRef.current.value !== '') {
        setHasValue(true)
      } else {
        setHasValue(false)
      }
    }
  }, [textAreaRef?.current?.value])
  return (
    <div
      className={`${className}${
        value[slugify(name)] || isFocused ? ' active' : ''
      }${required ? ' required' : ''}`}
    >
      <label
        htmlFor={name}
        className={isFocused || hasValue ? 'label--active' : ''}
      >
        {placeholder}
        {required && `*`}
      </label>
      <textarea
        ref={textAreaRef}
        name={slugify(name)}
        id={slugify(name)}
        placeholder={placeholder}
        required={!!required}
        onChange={handleChange}
        value={value[slugify(name)]}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
      />
    </div>
  )
}
